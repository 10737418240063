// import React, { useState } from 'react'; 
import { Navbar, Nav, Container, Form, FormControl } from 'react-bootstrap';

const AppNavbar = ({ onSearch }) => {
  // const [searchQuery, setSearchQuery] = useState('');

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   onSearch(searchQuery);
  // };

  const navbarStyle = {
    backgroundColor: '#D0F0C0', // Example style
    position: 'fixed',
    top: 0,
    width: '100%',
    paddingTop: 0,     // Set top padding to 0
    paddingBottom: 0,
    marginTop: 0,     // Set top padding to 0
    marginBottom: 0,
    
  };

  const logoStyle = {
    width: '100px',          // Make the logo wider
    marginTop: '-30px',     // Move the logo upwards
    marginBottom: '-10px',
  }

  return (
    <Navbar style={navbarStyle} variant="light" expand="lg" fixed="top">
      <Container>
      <Navbar.Brand href="/">
          <img
            src={`${process.env.PUBLIC_URL}/logo64deals.png`} // Path to logo in the public folder
            style={logoStyle}
            className="d-inline-block align-top"
            alt="64Deals logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {/* <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="#deals">Deals</Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link>
          </Nav> */}
          {/* <Form className="d-flex" onSubmit={handleSearch}>
            <FormControl
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button type="submit" variant="outline-success">Search</Button>
          </Form> */}
          {/* <Form className="d-flex ms-3">
            <FormControl
              as="select"
              aria-label="Online Stores"
              onChange={(e) => {
                const url = e.target.value;
                if (url) {
                  window.open(url, '_blank');
                }
              }}
            >
              <option value="">Select Store &darr;</option>
              <option value="https://amzn.to/3yNMxWG">Amazon</option>
              <option value="https://www.ebay.com">eBay</option>
              <option value="https://www.walmart.com">Walmart</option>
              <option value="https://www.target.com">Target</option>
            </FormControl>
          </Form> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;