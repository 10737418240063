import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DealItem = ({ deal }) => (
  // <Card className="mb-4">
  //   <Card.Img style={{ height: '300px' }} variant="top" src={deal.image} alt={deal.title} />
  //   <Card.Body>
  //     <Card.Title>{deal.title}</Card.Title>
  //     {/* <Card.Text>${deal.price}</Card.Text> */}
  //     <Card.Text>Price : Comind Soon</Card.Text>
  //     <Link to={`/item/${deal.id}`}>
  //       <Button variant="success">View Deal</Button>
  //     </Link>
  //   </Card.Body>
  // </Card>

  <Card className="mb-4">
    <Card.Img style={{ height: '300px' }} variant="top" src={`${process.env.PUBLIC_URL}/logo64deals.png`} alt={deal.title} />
    <Card.Body>
      <Card.Title>{deal.title}</Card.Title>
      {/* <Card.Text>${deal.price}</Card.Text> */}
      <Card.Text>Price : Comind Soon</Card.Text>
      <Link to="https://amzn.to/3yNMxWG">
        <Button variant="success">View Deal</Button>
      </Link>
    </Card.Body>
  </Card>
  // {/* <Card className="mb-4">
  //   <Card.Img style={{ height: '300px' }} variant="top" src={deal.image} alt={deal.title} />
  //   <Card.Body>
  //     <Card.Title>{deal.title}</Card.Title>
  //     {/* <Card.Text>${deal.price}</Card.Text> */}
  //     <Card.Text>Price : Comind Soon</Card.Text>
  //     <Link to={`/item/${deal.id}`}>
  //       <Button variant="success">View Deal</Button>
  //     </Link>
  //   </Card.Body>
  // </Card>
  // <Card className="mb-4">
  //   <Card.Img style={{ height: '300px' }} variant="top" src={deal.image} alt={deal.title} />
  //   <Card.Body>
  //     <Card.Title>{deal.title}</Card.Title>
  //     {/* <Card.Text>${deal.price}</Card.Text> */}
  //     <Card.Text>Price : Comind Soon</Card.Text>
  //     <Link to={`/item/${deal.id}`}>
  //       <Button variant="success">View Deal</Button>
  //     </Link>
  //   </Card.Body>
  // </Card> */}
);

export default DealItem;
