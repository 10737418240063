import React from 'react';
import { Row, Col } from 'react-bootstrap';
import DealItem from './DealItem';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';

const DealsList = ({ deals }) => (
  // <Row>
  //   {deals.map(deal => (
  //     <Col key={deal.id} sm={12} md={6} lg={3}>
  //       <DealItem deal={deal} />
  //     </Col>
  //   ))}
  // </Row>


  <Row>


    <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/acer.jpg`} alt='
Acer Swift Go Intel Evo Thin & Light Premium Laptop 14" 1920x1200 100% sRGB Touch Screen Display Intel Core i7-1355U Intel Iris Xe 16GB LPDDR5 512GB...' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/3X7fl4n">
        <Card.Title className="custom-card-text">Acer Swift Go Intel Evo Thin & Light Premium Laptop 14" 1920x1200 100% sRGB Touch Screen Display Intel Core i7-1355U Intel Iris Xe 16GB LPDDR5 512GB...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $599.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.3/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/hp2.jpg`} alt='
HP Newest 15.6" Multitasking Slim Laptop, 16GB RAM, 256GB Storage (128GB SSD Onboard and 128GB External Drive), Free Office 365 1-Year, Intel 4-core...' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/4g9TMZR">
        <Card.Title className="custom-card-text">HP Newest 15.6" Multitasking Slim Laptop, 16GB RAM, 256GB Storage (128GB SSD Onboard and 128GB External Drive), Free Office 365 1-Year, Intel 4-core...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $349.85</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.3/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/apple.jpg`} alt="Apple Watch SE (2nd Gen) [GPS 40mm] Smartwatch with Starlight Aluminum Case with Starlight Sport Band S/M. Fitness & Sleep Tracker, Crash Detection,..." />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/4g9U2bh">
        <Card.Title className="custom-card-text">Apple Watch SE (2nd Gen) [GPS 40mm] Smartwatch with Starlight Aluminum Case with Starlight Sport Band S/M. Fitness & Sleep Tracker, Crash Detection,...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $187.11</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.6/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/apple1.jpg`} alt="
Apple Watch Series 9 [GPS 41mm] Smartwatch with Pink Aluminum Case with Light Pink Sport Band S/M. Fitness Tracker, ECG Apps, Always-On Retina Display,..." />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/3ZpLQ0H">
        <Card.Title className="custom-card-text">Apple Watch Series 9 [GPS 41mm] Smartwatch with Pink Aluminum Case with Light Pink Sport Band S/M. Fitness Tracker, ECG Apps, Always-On Retina Display,...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $236.21</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.7/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/asus1.jpg`} alt="ASUS ROG Strix G16 (2024) Gaming Laptop, 16” 16:10 FHD 165Hz Display, NVIDIA® GeForce RTX™ 4060, Intel Core i7-13650HX, 16GB DDR5, 1TB PCIe Gen4 SSD, Wi-Fi..." />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/3AUIaJO">
        <Card.Title className="custom-card-text">ASUS ROG Strix G16 (2024) Gaming Laptop, 16” 16:10 FHD 165Hz Display, NVIDIA® GeForce RTX™ 4060, Intel Core i7-13650HX, 16GB DDR5, 1TB PCIe Gen4 SSD, Wi-Fi...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $1164.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt />
          </div>
          4.3/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/stanley.jpg`} alt="Stanley Quencher H2.0 FlowState Stainless Steel Vacuum Insulated Tumbler with Lid and Straw for Water, Iced Tea or Coffee" />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/4dD4dmX">
        <Card.Title className="custom-card-text">Stanley Quencher H2.0 FlowState Stainless Steel Vacuum Insulated Tumbler with Lid and Straw for Water, Iced Tea or Coffee</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $35.00</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          4.7/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/owala.jpg`} alt="Owala FreeSip Insulated Stainless Steel Water Bottle with Straw for Sports and Travel, BPA-Free, 24-oz, Very, Very Dark" />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/3yWbLlJ">
        <Card.Title className="custom-card-text">Owala FreeSip Insulated Stainless Steel Water Bottle with Straw for Sports and Travel, BPA-Free, 24-oz, Very, Very Dark</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $27.94</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          4.7/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/zevo.jpg`} alt="Zevo Flying Insect Trap for Indoors: Light Trap Captures Fruit Flies, Gnats and Houseflies, Starter Kit (1 Plug-in Base + 1 Cartridge)" />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/4g25cP3">
        <Card.Title className="custom-card-text">Zevo Flying Insect Trap for Indoors: Light Trap Captures Fruit Flies, Gnats and Houseflies, Starter Kit (1 Plug-in Base + 1 Cartridge)</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $19.36</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          4.3/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/julep.jpg`} alt="Julep Eyeshadow 101 Crème to Powder Waterproof Eyeshadow Stick, Champagne Shimmer" />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/3ALpYSY">
        <Card.Title className="custom-card-text">Julep Eyeshadow 101 Crème to Powder Waterproof Eyeshadow Stick, Champagne Shimmer</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $9.60</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar />
          </div>
          4.2/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/lenovo1.jpg`} alt='lenovo 15.6" Touchscreen 20GB RAM 1TB SSD IdeaPad 3i Laptop, Windows 11 Pro, Intel Core i5-1135G7 4-Core Processor, FHD 1920x1080 Display, SD Card Reader, HDMI, USB-C, Arctic Grey' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/3X5psXA">
        <Card.Title className="custom-card-text">lenovo 15.6" Touchscreen 20GB RAM 1TB SSD IdeaPad 3i Laptop, Windows 11 Pro, Intel Core i5-1135G7 4-Core Processor, FHD 1920x1080 Display, SD Card Reader, HDMI, USB-C, Arctic Grey</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $35.00</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          4.5/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/lenovo2.jpg`} alt="lenovo 15.6 Touchscreen 16GB RAM 1TB SSD IdeaPad 3i Laptop, Windows 11 Pro, Intel Core i5-1155G7 4-Core Processor, FHD 1920x1080 Display, Dolby Audio, Media..." />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/3Z4Njcg">
        <Card.Title className="custom-card-text">lenovo 15.6 Touchscreen 16GB RAM 1TB SSD IdeaPad 3i Laptop, Windows 11 Pro, Intel Core i5-1155G7 4-Core Processor, FHD 1920x1080 Display, Dolby Audio, Media...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $579.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          4.4/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/hp1.jpg`} alt='HP Notebook Laptop, 15.6" HD Touchscreen, Intel Core i3-1115G4 Processor, 32GB RAM, 1TB PCIe SSD, Webcam, Type-C, HDMI, SD Card Reader, Wi-Fi, Windows...' />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/3XqKQaQ">
        <Card.Title className="custom-card-text">HP Notebook Laptop, 15.6" HD Touchscreen, Intel Core i3-1115G4 Processor, 32GB RAM, 1TB PCIe SSD, Webcam, Type-C, HDMI, SD Card Reader, Wi-Fi, Windows...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $442.60</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStar />
          </div>
          4.2/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/asus.jpg`} alt="ASUS Vivobook 16” WUXGA Laptop, Intel Core i5-1235U, Intel Iris Xe Graphics, 16GB Memory, 512GB SSD, Cool Silver, F1605ZA-AS56" />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/4fZp99r">
        <Card.Title className="custom-card-text">ASUS Vivobook 16” WUXGA Laptop, Intel Core i5-1235U, Intel Iris Xe Graphics, 16GB Memory, 512GB SSD, Cool Silver, F1605ZA-AS56</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $449.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
            <FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          3.7/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/keurig.jpg`} alt="
Keurig K-Mini Single Serve Coffee Maker, Black" />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/3TaQITa">
        <Card.Title className="custom-card-text">
        Keurig K-Mini Single Serve Coffee Maker, Black</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $59.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          4.5/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>
  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/ssdsamsung.jpg`} alt="SAMSUNG T7 Portable SSD, 4TB External Solid State Drive, Speeds Up to 1,050MB/s, USB 3.2 Gen 2, Reliable Storage for Gaming, Students, Professionals,..." />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/4cMZais">
        <Card.Title className="custom-card-text">
        SAMSUNG T7 Portable SSD, 4TB External Solid State Drive, Speeds Up to 1,050MB/s, USB 3.2 Gen 2, Reliable Storage for Gaming, Students, Professionals,...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $279.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
          </div>
          4.8/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>

  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/sceptre.jpg`} alt="Sceptre 34-Inch Curved Ultrawide WQHD Monitor 3440 x 1440 R1500 up to 165Hz DisplayPort x2 99% sRGB 1ms Picture by Picture, Machine Black 2023 (C345B-QUT168)" />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/4e5ALFM">
        <Card.Title className="custom-card-text">
        Sceptre 34-Inch Curved Ultrawide WQHD Monitor 3440 x 1440 R1500 up to 165Hz DisplayPort x2 99% sRGB 1ms Picture by Picture, Machine Black 2023 (C345B-QUT168)</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $219.97</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          4.5/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>

  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/waterpik.jpg`} alt="
Waterpik Aquarius Water Flosser Professional For Teeth, Gums, Braces, Dental Care, Electric Power With 10 Settings, 7 Tips For Multiple Users And Needs, ADA..." />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/3MpU1SK">
        <Card.Title className="custom-card-text">
        Waterpik Aquarius Water Flosser Professional For Teeth, Gums, Braces, Dental Care, Electric Power With 10 Settings, 7 Tips For Multiple Users And Needs, ADA...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $59.37</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStarHalfAlt /> 
          </div>
          4.6/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>

  <Col sm={12} md={6} lg={3}>
    <Card className="custom-mb-4 h-100">

    <div className="d-flex justify-content-center">
    
      <Card.Img style={{ height: '200px', width: 'auto', padding: '20px' }} variant="top" src={`${process.env.PUBLIC_URL}/ninja.jpg`} alt="Ninja Air Fryer Pro 4-in-1 with 5 QT Capacity, Air Fry, Roast, Reheat, Dehydrate, Air Crisp Technology with 400F for hot, crispy results in just minutes,..." />

      </div>
      <Card.Body>
      <Link className="custom-link" to="https://amzn.to/4cIWHFX">
        <Card.Title className="custom-card-text">Ninja Air Fryer Pro 4-in-1 with 5 QT Capacity, Air Fry, Roast, Reheat, Dehydrate, Air Crisp Technology with 400F for hot, crispy results in just minutes,...</Card.Title>
        {/* <Card.Text>${deal.price}</Card.Text> */}
        <Card.Text className="custom-card-text">Price : $89.99</Card.Text>
        <div style={{ color: 'gold', marginBottom: '10px' }}>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
          </div>
          4.8/5 <br/>
        
          <Button variant="success">View Deal</Button>
        </Link>
      </Card.Body>
      

    </Card>
  </Col>

  </Row>
);

export default DealsList;
